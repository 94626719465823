body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background-color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.done {
  text-decoration: line-through;
}

header {
  padding: 8px;
  margin-bottom: 16px;
}

.todo-card {
  padding: 4px 8px;
}

.todo-card button {
  margin: 0 8px;
}

.App-header {
  display: flex;
  justify-content: space-between;
  box-shadow: 2px -2px 5px 5px #00000033;
  background-color: var(--background-color);
}

main {
  padding: 16px;
}

.error {
  padding: 8px;
  background-color: #ff000044;
  border-radius: 3px;
}